<template>
  <v-container class="my-10 mb-8 pb-8 kyc_form">
    <h2>Bhrikuti Stock Forms</h2>

    <v-divider class="my-8 mb-10"> </v-divider>

    <v-data-table :headers="headers" :items="forms" class="elevation-1" hide-default-footer>
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.sn }}</td>
          <td>{{ row.item.name }}</td>
          <td>
            <v-btn :href="row.item.href" color="primary" text>Fill Up Form</v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn class="mt-5" variant="text" elevation="0" style="text-transform: capitalize; letter-spacing: 0px;"
      href="https://bhrikutistock.com" color="success">Go Back to
      Bhrikuti Stock Website <v-icon right>mdi-open-in-new</v-icon></v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
    headers: [
      {
        text: 'S.N.',
        align: 'start',
        sortable: false,
        value: 'sn',
      },
      {
        text: 'Form Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'href',
      },
    ],
    forms: [
      {
        sn: 1,
        name: 'Individual KYC Form',
        href: '/KYC',
      },
      {
        sn: 2,
        name: 'Individual DEMAT Account Form',
        href: '/DEMAT',
      },
      {
        sn: 3,
        name: "Minor to Individual Account Transfer",
        href: "/minor-to-individual-transfer"
      },
      {
        sn: 4,
        name: "Securities Transfer Form",
        href: "/securities-transfer-form"
      },
      {
        sn: 5,
        name: "Letter of Consent (मन्जुरीनामा)",
        href: "/letter-of-consent"
      },
      {
        sn: 6,
        name: "Dematerialization Order (अभौतिकीकरण आदेश)",
        href: "/dematerialization-order"
      },
      {
        sn: 7,
        name: "Posthumous Securities Transfer",
        href: "/posthumous-security-transfer"
      },
    ],
  }),
  methods: {},
};
</script>

<style scoped>
/* Custom CSS to reduce the gap between stepper headers */
.kyc_form {
  font-family: 'Montserrat', sans-serif !important;
}

.snackbar__message {
  font-weight: 200 !important;
  font-size: 1rem;
}

.custom-input {
  margin-top: 1rem;

  font-family: 'Inter', sans-serif !important;
  font-size: 1rem;

  border-radius: 0.2em;

  outline: none;
}

.cardTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}
</style>
